import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../img/Logo.png";
import axios from "axios";
import globalVariables from "../../GlobalVariable";
import UserUpdate from "./ModalUserUpdate";
import ModalAllUserUpdate from "./ModalAllUserUpdate";

const Navbar = ({ setShowModal, businessName }) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpenAllUsers, setIsMenuOpenAllUsers] = useState(false);
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    last_name: "",
    email: "",
    password: "",
    // roles_ids: [],
    // business_yelp_ids: [],
  });
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("user_id");

    if (token && !isLoaded) {
      const fetchData = async () => {
        try {
          // Obtener roles
          const rolesResponse = await axios.get(
            `${globalVariables.domain}/role`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setRoles(rolesResponse.data.content);

          // Obtener datos del usuario
          const userResponse = await axios.get(
            `${globalVariables.domain}/user/${user_id}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          if (userResponse.data.success) {
            setUser(userResponse.data.content);
            const userRoles = userResponse.data.content.roles_ids;
            const userRolesDetails = rolesResponse.data.content.filter((role) =>
              userRoles.includes(role._id)
            );
            const adminRole = userRolesDetails.some(
              (role) => role.role_name === "admin"
            );
            setIsAdmin(adminRole);

            setUserData({
              _id: userResponse.data.content._id,
              name: userResponse.data.content.name,
              last_name: userResponse.data.content.last_name,
              email: userResponse.data.content.email,
              password: "",
              roles_ids: userResponse.data.content.roles_ids,
              business_yelp_ids: userResponse.data.content.business_yelp_ids,
            });
          }

          // Obtener todos los usuarios
          const allUsersResponse = await axios.get(
            `${globalVariables.domain}/user/all_users`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          if (allUsersResponse.data.success) {
            setAllUsers(allUsersResponse.data.content);
          }

          setIsLoaded(true);
        } catch (error) {
          console.error("Error al obtener datos:", error);
        }
      };

      fetchData();
    }
  }, [isLoaded]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    // setIsMenuOpenAllUsers(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsMenuOpenAllUsers(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("user_id");

    axios
      .patch(`${globalVariables.domain}/user/${user_id}`, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setUser(response.data.content);
          handleCloseModal();
          console.log("Usuario actualizado exitosamente");
        }
      })
      .catch((error) => {
        console.error("Error al actualizar el usuario:", error);
      });
  };

  return (
    <nav className="p-4 bg-white">
      <div className="container mx-auto">
        <div className="flex flex-row justify-between items-center">
          <div className="flex justify-center text-center">
            <p className="flex flex-row text-sm">
              Welcome to {businessName},{" "}
              <span className="font-bold text-orange-500 ml-2">
                {" "}
                {user?.name}
              </span>
            </p>
          </div>
          <div className="hidden lg:flex items-center gap-2">
            <button
              onClick={handleOpenModal}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
            >
              Edit User
            </button>
            {isAdmin && (
              <div className="flex flex-row gap-2">
                <button
                  onClick={() => setIsMenuOpenAllUsers(true)}
                  className="btn btn-primary px-4 py-2 bg-blue-600 text-white rounded shadow hover:bg-blue-700 transition duration-200"
                >
                  Modify Users
                </button>
                <button
                  onClick={() => setShowModal(true)}
                  className="btn btn-primary px-4 py-2 bg-blue-600 text-white rounded shadow hover:bg-blue-700 transition duration-200"
                >
                  Manage
                </button>
              </div>
            )}
            <button
              onClick={handleLogout}
              className="text-white bg-orange-500 px-4 py-2 rounded-md hover:bg-orange-700 transition duration-300"
            >
              Sign Out
            </button>
          </div>

          <div className="lg:hidden">
            <button
              onClick={toggleMenu}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>

        {isMenuOpen && (
          <div className="flex flex-col gap-2 rounded-xl lg:hidden mt-4 bg-gray-100 animate-fade-down">
            <button
              onClick={handleOpenModal}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
            >
              Edit User
            </button>
            {isAdmin && (
              <button
                onClick={() => setIsMenuOpenAllUsers(true)}
                className="btn btn-primary px-4 py-2 bg-blue-600 text-white rounded shadow hover:bg-blue-700 transition duration-200"
              >
                Modify Users
              </button>
            )}
            {isAdmin && (
              <button
                onClick={() => setShowModal(true)}
                className="btn btn-primary px-4 py-2 bg-blue-600 text-white rounded shadow hover:bg-blue-700 transition duration-200"
              >
                Manage
              </button>
            )}
            <button
              onClick={() => {
                handleLogout();
                setIsMenuOpen(false);
              }}
              className="btn btn-primary px-4 py-2 bg-orange-500 text-white rounded shadow hover:bg-orange-700 transition duration-200"
            >
              Sign Out
            </button>
          </div>
        )}
        <div className="flex flex-col pt-4 justify-center items-center">
          <img src={Logo} alt="Logo" className="w-32 md:w-96 " />
          <h1 className="ml-2 text-3xl font-bold">Yelp Leads</h1>
        </div>
      </div>
      {isModalOpen && (
        <UserUpdate
          handleCloseModal={handleCloseModal}
          userData={userData}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
        />
      )}
      {isMenuOpenAllUsers && (
        <ModalAllUserUpdate
          setUserData={setUserData}
          handleCloseModal={handleCloseModal}
          userData={userData}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
          setAllUsers={setAllUsers}
          allUsers={allUsers}
        />
      )}
    </nav>
  );
};

export default Navbar;
