import React from "react";
import Header from "./Components/Header"

const HomePage = () => {
    return (
        <body>
            <Header/>
        </body>
    )

}

export default HomePage;