import React from "react";
import axios from "axios";
import globalVariables from "../../GlobalVariable";

const ModalUserUpdate = ({
  handleCloseModal,
  handleInputChange,
  userData,
  setUserData,
  allUsers,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .patch(`${globalVariables.domain}/user/`, userData, {
        body: {
          _id: userData._id,
          name: userData.name,
          last_name: userData.last_name,
          email: userData.email,
          password: userData.password,
          roles_ids: userData.roles_ids,
          business_yelp_ids: userData.business_yelp_ids,
          approved: userData.approved,
        },
      })
      .then((response) => {
        if (response.data.success) {
          handleCloseModal();
          alert("User updated successfully");
        } else {
          console.error("Error in response:", response.data.message);
          alert("Error updating user: " + response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error updating user", error);
        alert("Error updating user");
      });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-40 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-11/12 md:w-1/3">
        <form onSubmit={handleSubmit}>
          <label className="block mb-2">
            Name:
            <input
              type="text"
              name="name"
              value={userData.name} // Cambiado para referirse a userData
              onChange={handleInputChange}
              required
              className="mt-1 block w-full border rounded-md p-2 border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
            />
          </label>
          <label className="block mb-2">
            Last Name:
            <input
              type="text"
              name="last_name"
              value={userData.last_name} // Cambiado para referirse a userData
              onChange={handleInputChange}
              required
              className="mt-1 block w-full border rounded-md p-2 border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
            />
          </label>
          <label className="block mb-2">
            Email:
            <input
              type="email"
              name="email"
              value={userData.email} // Cambiado para referirse a userData
              onChange={handleInputChange}
              required
              className="mt-1 block w-full border rounded-md p-2 border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
            />
          </label>
          <label className="block mb-2">
            Password:
            <input
              type="password"
              name="password"
              value={userData.password} // Cambiado para referirse a userData
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2 border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
            />
          </label>
          <div className="flex flex-row justify-between gap-2">
            <button
              onClick={handleCloseModal}
              className="w-full bg-orange-500 text-white py-2 rounded hover:bg-orange-600"
            >
              Close
            </button>
            <button
              type="submit"
              className="w-full bg-green-500 text-white py-2 rounded hover:bg-green-600"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalUserUpdate;
